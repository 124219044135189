<template>
  <div id="slotWrap" :class="{'slot_menuBg' : slotTab === 'menu1'}">
    <ul class="menuWrap">
      <li @click="slotTab = 'menu1'" :class="{ 'currentMenu' : slotTab === 'menu1'}">slot 플레이</li>
      <li @click="slotTab = 'menu2'" :class="{ 'currentMenu' : slotTab === 'menu2'}">slot 충전</li>
      <li @click="slotTab = 'menu3'" :class="{ 'currentMenu' : slotTab === 'menu3'}">slot 환전</li>
    </ul>
    <div class="slotContWrap" v-if="slotTab === 'menu1'" :class="{'slot_menu1' : slotTab === 'menu1'}">
      <div class="slottitle">
        <img src="@/assets/img/slot/slot_logo.svg">
        <p>승부사 본능을 깨워라!<br> 치열한 두뇌싸움의 정석<br>최고의 슬롯 게임에 지금 도전하세요.</p>
      </div>
      <ul class="betGames betGamesc">
        <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
          <li v-if="item.code === '500'" @click="slotOpen('isSlot', 'slot', codeName, code)">
            <div class="mask">
                <img src="@/assets/img/icon_play.svg">
            </div>
            <div :style="getBackgroundImage(item)" class="gameImg"></div>
            <div class="glogoWrap"><em class="glogo" :style="getBackgroundLogoImage(item)"></em></div>
            <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
            </p>
          </li>
        </template>
      </ul>
      <!-- <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey" >
        <button v-if="item.code === '500'" @click="onCasinoSelectGame(item.groupCode, item.code)">실행</button>
      </template> -->
    </div>
    <div class="slotContWrap slotTab2" v-if="slotTab === 'menu2'">
      <div class="tabBg"></div>
      <div class="slot_content">
        <div class="slot-noti">
          <h4>확인 및 필독사항</h4>
          <ul>
            <li>슬롯 게임을 플레이하기 위해서는 슬롯 머니로 보유금 전환을 하셔야 합니다.</li>
            <li>충전하실 금액을 선택하거나 입력해 주세요.</li>
            <li>확인 버튼을 클릭하시면 전환이 완료됩니다.</li>
            <li>입금액은 1,000원이상 1,000원 단위입니다.</li>
            <li>기존 입금액은 최근 5회까지 조회됩니다.</li>
          </ul>
        </div>
        <div class="slot-charge">
          <table>
            <tr>
              <td>현재 보유머니</td>
              <td><span class="money">{{ thousand(userData.cashAmt) }}</span> 원</td>
            </tr>
            <tr>
              <td>슬롯 머니</td>
              <td><span class="money"> {{ thousand(slotGateMoney)}}</span> 원</td>
            </tr>
            <tr>
              <td>슬롯 전환 금액</td>
              <td>
                <div>
                  <input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="setCurrentChargeMoney" />원
                </div>
                <ul class="cashBtnWrap">
                  <li><button @click="setMoney(10000)">1만원</button></li>
                  <li><button @click="setMoney(30000)">3만원</button></li>
                  <li><button @click="setMoney(50000)">5만원</button></li>
                  <li><button @click="setMoney(100000)">10만원</button></li>
                  <li><button @click="setMoney(300000)">30만원</button></li>
                  <li><button @click="setMoney(500000)">50만원</button></li>
                  <li><button @click="setMoney(1000000)">100만원</button></li>
                  <li><button @click="setMoney(0)">정정</button></li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <button class="exBtn" @click="onClickInTM">슬롯머니로 전환하기</button>
        <h3>슬롯 전환내역</h3>
        <div class="slot-list">
          <table>
            <colgroup>
              <col style="width: 10%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
            </colgroup>
            <tr>
              <th>번호</th>
              <th>전환일자</th>
              <th>전환금액</th>
              <th>처리현황</th>
            </tr>
            <template v-if="cashInList">
              <template v-if="cashInList.length > 0">
                <template v-for="(item, index) in cashInList" :key="item.updDate">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.updDate.split(' ')[0] }}</td>
                    <td>{{thousand(item.cashAmt)}}원</td>
                    <td>{{ item.cashStatus }}</td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="4">내역이 없습니다.</td>
                </tr>
              </template>
            </template>
          </table>
        </div>
        <button class="cancelBtn">전체 삭제</button>
      </div>
    </div>
    <div class="slotContWrap slotTab2" v-if="slotTab === 'menu3'">
      <div class="tabBg"></div>
      <div class="slot_content">
        <div class="slot-noti">
          <h4>확인 및 필독사항</h4>
          <ul>
            <li>언제든 슬롯 머니를 보유금으로 전환하실 수 있습니다.</li>
            <li>확인 버튼을 클릭하시면 보유하신 슬롯 머니 전환이 완료됩니다.</li>
          </ul>
        </div>
        <div class="slot-charge">
          <table>
            <tr>
              <td>현재 보유머니</td>
              <td><span class="money">{{ thousand(userData.cashAmt) }}</span> 원</td>
            </tr>
            <tr>
              <td>슬롯 머니</td>
              <td><span class="money"> {{ thousand(slotGateMoney)}}</span> 원</td>
            </tr>
            <tr>
              <td>보유금 전환 금액</td>
              <td>
                <div>
                  <input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="getCurrentChargeMoney" />원
                </div>
                <ul class="cashBtnWrap">
                  <li><button @click="getMoney(10000)">1만원</button></li>
                  <li><button @click="getMoney(30000)">3만원</button></li>
                  <li><button @click="getMoney(50000)">5만원</button></li>
                  <li><button @click="getMoney(100000)">10만원</button></li>
                  <li><button @click="getMoney(300000)">30만원</button></li>
                  <li><button @click="getMoney(500000)">50만원</button></li>
                  <li><button @click="getMoney(1000000)">100만원</button></li>
                  <li><button @click="getMoney(0)">정정</button></li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <button class="exBtn" @click="onClickOutTM">보유금으로 전환하기</button>
        <h3>보유금 전환내역</h3>
        <div class="slot-list">
          <table>
            <colgroup>
              <col style="width: 10%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
            </colgroup>
            <tr>
              <th>번호</th>
              <th>환전일자</th>
              <th>환전금액</th>
              <th>처리현황</th>
            </tr>
            <template v-if="cashOutList">
              <template v-if="cashOutList.length > 0">
                <template v-for="(item, index) in cashOutList" :key="item.updDate">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.updDate.split(' ')[0] }}</td>
                    <td>{{thousand(item.cashAmt)}}원</td>
                    <td>{{ item.cashStatus }}</td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="4">내역이 없습니다.</td>
                </tr>
              </template>
            </template>
          </table>
        </div>
        <button class="cancelBtn">전체 삭제</button>
      </div>
    </div>
  </div>

  <transition name="fade">
    <TMmodal v-show="TMmodal" :isOpen="TMmodal" :type="'header'" @close="onSubmit" @cancel="onCloseTM"/>
  </transition>
  <transition name="fade">
    <TMmodal v-show="TMOutmodal" :isOpen="TMOutmodal" :type="'header'" @close="onSubmitOut" @cancel="onCloseOutTM"/>
  </transition>

  <slot-list v-if="isSlotOpen" :groupCode="groupCode" :codeName="codeName" :code="code" @isSlot="isSlotOpen = false"></slot-list>
</template>
<script>
import { mapState } from 'vuex'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import TMmodal from '@/components/common/TotalMoney.vue'
import { cashList, getSlotGateCash, slotGateMoneyDeposit, slotGateMoneyWithdraw } from '@/api/cash'
import store from '@/store'
import SlotList from '@/components/game/SlotList.vue'

export default {
  name: 'slotMain',
  components: { SlotList, TMmodal },
  data () {
    return {
      isSlotOpen: false,
      slotTab: 'menu2',
      slotGateMoney: '0',
      setCurrentChargeMoney: '',
      getCurrentChargeMoney: '',
      TMmodal: false,
      TMOutmodal: false,
      cashInList: null,
      cashOutList: null,
      codeName: null,
      code: null
    }
  },
  computed: {
    ...mapState([
      'commonCode',
      'commonCodeByOrder',
      'gameCount',
      'userData'
    ])
  },
  watch: {
    setCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.setCurrentChargeMoney = n
    },
    getCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.getCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.getCurrentChargeMoney = n
    }
  },
  mounted () {
    this.emitter.on('isSlot', (data) => {
      if (data) {
        const groupCode = data.groupCode
        const codeName = data.codeName
        const code = data.code
        this.getSlotOpen(groupCode, codeName, code)
      } else {
        this.isSlotOpen = false
      }
    })
  },
  created () {
    this.codeName = this.$route.query.codeName
    this.code = this.$route.query.code
    this.getSlotGateMoney()
    this.getCashList('SG1')
    this.getCashList('SG2')
  },
  methods: {
    thousand,
    getSlotOpen (groupCode, codeName, code) {
      if (this.userData) {
        this.groupCode = groupCode
        this.codeName = codeName
        this.code = code
        this.isSlotOpen = true
      } else {
        this.onCheck('front.error.afterSignin')
      }
    },
    onCloseTM () {
      this.TMmodal = false
    },
    onCloseOutTM () {
      this.TMOutmodal = false
    },
    onClickInTM () {
      const chargeMoney = this.setCurrentChargeMoney.replace(/,/g, '')
      const userCashAmt = this.userData.cashAmt

      if (Number(chargeMoney) > Number(userCashAmt)) {
        this.onCheck('전환금액이 현재 보유머니보다 큽니다.')
        return false
      }

      document.body.style.overflow = 'hidden'
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      // if (!this.cashOutPass) {
      //   this.onCheck('출금 비밀번호를 입력해주세요.')
      //   return false
      // }
      this.emitter.emit('Loading', true)
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        this.TMmodal = true
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
    },
    onClickOutTM () {
      const chargeMoney = this.setCurrentChargeMoney.replace(/,/g, '')
      const slotGateMoney = this.slotGateMoney

      if (Number(chargeMoney) > Number(slotGateMoney)) {
        this.onCheck('전환금액이 슬롯머니보다 큽니다.')
        return false
      }

      document.body.style.overflow = 'hidden'
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      // if (!this.cashOutPass) {
      //   this.onCheck('출금 비밀번호를 입력해주세요.')
      //   return false
      // }
      this.emitter.emit('Loading', true)
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        this.TMOutmodal = true
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
    },
    async onSubmit (device = 'mobile') {
      this.emitter.emit('Loading', false)
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmCharge')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              amount: this.setCurrentChargeMoney.replace(/,/g, '')
            }

            slotGateMoneyDeposit(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                const result = response.data

                if (result.resultCode === '0') {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('front.cash.completeCharge')
                  if (confirm) {
                    location.reload()
                  }
                } else {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('api.' + result.resultCode)
                  if (confirm) {
                    location.reload()
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    },
    async onSubmitOut (device = 'mobile') {
      this.emitter.emit('Loading', false)
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmExchange')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              amount: this.getCurrentChargeMoney.replace(/,/g, '')
            }

            slotGateMoneyWithdraw(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                const result = response.data

                if (result.resultCode === '0') {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('front.cash.completeExchange')
                  if (confirm) {
                    location.reload()
                  }
                } else {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('api.' + result.resultCode)
                  if (confirm) {
                    location.reload()
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    },
    setMoney (value) {
      let money = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.setCurrentChargeMoney = thousand(money)
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
    },
    getMoney (value) {
      let money = Number(this.getCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        if (money + value <= this.userData.cashAmt) {
          money += value
        } else {
          this.onCheck('환전 금액이 현재 보유캐시보다 큽니다.')
        }
      } else {
        money = value
      }

      this.getCurrentChargeMoney = thousand(money)
    },
    getSlotGateMoney () {
      console.log('getSlotGateMoney')
      getSlotGateCash().then(res => {
        console.log(res)
        const result = res.data
        if (result.resultCode === '0') {
          this.slotGateMoney = result.data.userBalance
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('@/assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('@/assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getCashList (type) {
      const params = {
        cashType: type,
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      }

      cashList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'SG1') {
            this.cashInList = list
          } else if (type === 'SG2') {
            this.cashOutList = list
          }

          console.log(type, list)
        }
      })
    },
    slotOpen (event, groupCode, codeName, code) {
      console.log('test : ', event, groupCode, codeName, code)
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/main.css"></style>
<style scoped>
.betGames {display: flex;justify-content: center;}
#slotWrap {
  width: 100%;
  padding: 20px;
  height: 749px;
  overflow: hidden;
  box-sizing: border-box;
}
#slotWrap::-webkit-scrollbar {
  width: 0;
}
.menuWrap {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.menuWrap li {
  width: 32%;
  max-width: 380px;
  box-sizing: border-box;
  font-size: 18px;
  padding: 14px 0;
  border-radius: 5px;
  color: #fff;
  -webkit-backdrop-filter: blur(27.1px);
  backdrop-filter: blur(27.1px);
  box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
  border: solid 1px #373139;
  cursor: pointer;
  text-align: center;
  transition: 0.5s;
}
.menuWrap li.currentMenu {
  box-shadow: 0 0 10px 0 #275ef3;
  background-image: linear-gradient(to right, #1233db 0%, #2966f8 50%, #1d40e0 100%);
}
.menuWrap li:hover {
  box-shadow: 0 0 10px 0 #275ef3;
  background-image: linear-gradient(to right, #1233db 0%, #2966f8 50%, #1d40e0 100%);
}
.slotTab2 > div {
  box-sizing: border-box;
}
.slotTab2 > div.slot_content {
  margin: 20px auto;
  width: 90%;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
}
.slotContWrap {
  width: 100%;
  margin: 20px auto;
  height: calc(100% - 30px);
  overflow-y: scroll;
  z-index: 1;
}
.slot_content > div {
  padding: 20px 25px;
  border-radius: 5px;
  border: solid 1px #373139;
  -webkit-backdrop-filter: blur(27.1px);
  backdrop-filter: blur(27.1px);
  box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
}
.slot_menuBg{
  background: url(~@/assets/img/slot/slot_bg.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.slot_menu1 button{
  width: 150px;
  height: 50px;
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 40px;
  border-radius: 5px;
  border: 0;
  font-family: 'SUIT';
  font-size: 22px;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
  font-weight: bold;
  color: #fff;
  background-image: linear-gradient(to right, #1233db, #2966f8 50%, #1d40e0);
  box-shadow: 0 0 10px 0 #275ef3;
  cursor: pointer;
}
.slottitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  margin-top: 65px;
}
.slottitle img{
  width: 38%;
}
.slottitle p {
  font-size: 22px;
  letter-spacing: 0.5px;
  padding-bottom: 50px;
}
.slottitle p br:nth-child(1) {
  display: none;
}
.slotbg {
  position: relative;
  height: 440px;
}
.slotContWrap::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.slotContWrap::-webkit-scrollbar-thumb{
  border-radius: 3px;
  background-color: gray;
}
.slotContWrap::-webkit-scrollbar-track{
  background-color: transparent;
}
.slotContWrap::-webkit-scrollbar-button{
  width: 0;
  height: 0;
}
.slotTab2 {
  position: relative;
}
.tabBg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url(~@/assets/img/slot/circle.svg) no-repeat;
  background-size: 150%;
  background-position: center center;
  -webkit-filter: blur(50px);
  filter: blur(50px);
}
.slot-noti{
  background: #ffffff0d;
  padding: 20px;
}
.slot-noti h4 {
  font-size: 20px;
  margin-bottom: 20px;
}
.slot-noti ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
}
.slot-noti ul li {
  position: relative;
  font-family: 'SUIT';
  font-size: 14px;
  font-weight: 500;
}
.slot-noti ul li::after {
  position: absolute;
  content: '✔';
  left: -20px;
  top: 0;
}
.slot-charge {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.slot-charge table {
  width: 100%;
}
.slot-charge tr {
  border-bottom: 1px solid #373139;
}
.slot-charge tr:last-child {
  border-bottom: 0;
}
.slot-charge td{
  padding: 15px 20px;
}
.slot-charge td:first-child {
  min-width: 140px;
  width: 15%;
}
.slot-charge td:last-child {
  position: relative;
  width: 85%;
}
.slot-charge td .money {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
  font-family: 'SUIT';
  font-size: 16px;
  font-weight: 800;
  color: #5c8cff;
  padding-left: 20px;
}
.slot-charge td ul {
  display: flex;
  gap: 5px;
}
.slot-charge td ul li button {
  height: 30px;
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #373139;
  color: #fff;
  background: none;
  border-radius: 5px;
  font-size: 13px;
  font-family: 'SUIT';
  padding: 0 10px;
  cursor: pointer;
  -webkit-backdrop-filter: blur(27.1px);
  backdrop-filter: blur(27.1px);
  box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
}
.slot-charge input {
  background: none;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 310px;
  padding: 5px;
  margin-bottom: 10px;
  color: #5c8cff;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 800;
}
.exBtn {
  width: 250px;
  height: 44px;
  border-radius: 5px;
  border: 0;
  font-family: 'SUIT';
  font-size: 18px;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
  font-weight: bold;
  color: #fff;
  background-image: linear-gradient(to right, #1233db, #2966f8 50%, #1d40e0);
  box-shadow: 0 0 10px 0 #275ef3;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}
.cancelBtn {
  width: 110px;
  height: 34px;
  border-radius: 5px;
  border: 0;
  font-family: 'SUIT';
  font-size: 14px;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
  -webkit-backdrop-filter: blur(27.1px);
  backdrop-filter: blur(27.1px);
  background: none;
  color: #fff;
  box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
  border: solid 1px #373139;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}
.slot-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h3 {
  font-size: 22px;
  width: 100%;
}
.slot-list table {
  width: 100%;
}
.slot-list tr {
  border-bottom: 1px solid #373139;
}
.slot-list th {
  padding: 15px;
}
.slot-list td {
  padding: 15px;
  text-align: center;
}
.slot-list tr:last-child {
  border-bottom: 0;
}
.slot-list button {
  width: 112px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: #fff;
  border-radius: 50px;
  margin: 20px 0 40px;
  cursor: pointer;
}
.cashBtnWrap li:nth-child(1) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(34, 175, 254, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(2) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(213, 171, 57, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(3) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(41, 183, 118, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(4) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(129, 69, 247, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(5) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(252, 94, 116, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(6) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(248, 127, 76, 0.7), rgba(128, 128, 128, 0) 81%);
}
.cashBtnWrap li:nth-child(7) button{
  background-image: radial-gradient(circle at 50% 150%, rgba(227, 64, 249, 0.7), rgba(128, 128, 128, 0) 81%);
}
@media (max-width: 700px) {
  #slotWrap {
    padding: 20px;
  }
  .menuWrap {
    width: 100%;
  }
  .slotTab2 > div.slot_content {
    width: 100%;
    margin-top: 0;
  }
  .slot_content > div {
    padding: 15px 14px;
  }
  .slottitle {
    margin-top: 45px;
  }
  .slottitle img {
    display: none;
  }
  .slottitle p {
    font-size: 20px;
    line-height: 2;
  }
  .slottitle p br:nth-child(1) {
    display: inline;
  }
  .slot-noti h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .slot-noti ul {
    gap: 5px;
  }
  .slot-noti ul li {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 200;
  }
  .slot-charge tr {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
  }
  .slot-charge td{
    padding: 10px 0;
    font-size: 13px;
    box-sizing: border-box;
  }
  .slot-charge td:first-child {
    min-width: auto;
    width: 100%;
  }
  .slot-charge td:last-child {
    width: 100%;
  }
  .slot-charge td .money {
    padding: 5px 12px;
    width: calc(100% - 48px);
    display: inline-block;
    border-radius: 5px;
    border: solid 1px #373139;
    margin-right: 7px;
  }
  .slot-charge input {
    width: calc(100% - 23px);
    margin-right: 7px;
    padding-left: 12px;
  }
  .slot-charge td ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .slot-charge td ul li {
    width: calc(25% - 5px);
  }
  .slot-charge td ul li button {
    height: 30px;
    width: 100%;
    font-size: 12px;
  }
  .slot-list h3 {
    font-size: 17px;
  }
  .slot-list th {
    padding: 10px 5px;
    font-size: 12px;
  }
  .slot-list td {
    padding: 10px 5px;
    font-size: 12px;
  }
}
</style>
